<template>
    <div class="audit-recordlist page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item>
                <el-input v-model="searchData.queryText" placeholder="修改、行为、字段、位置、关键词" clearable class="input-with-select" @keyup.enter.native.prevent="searchHandle(2)">
                    <el-button slot="append" icon="el-icon-search" @click="searchHandle"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="text" @click="clearSearchHandle">清除搜索</el-button>
            </el-form-item>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <el-table class="audit-recordlist-table" ref="table" :data="tableList" border style="width: 100%">
            <el-table-column type="index" label="#" align="center" width="50"></el-table-column>
            <el-table-column prop="comicName" label="漫画名称" align="center"></el-table-column>
            <el-table-column prop="editField" label="编辑字段" align="center"></el-table-column>
            <el-table-column prop="action" label="编辑行为" align="center" width="80"></el-table-column>
            <el-table-column prop="editDetail" label="编辑细节" align="center" width="80"></el-table-column>
            <el-table-column prop="source" label="修改前" align="center" width="210">
                <template slot-scope="scope">
                   <auditComicRecordCol :publicClass="publicClass" :editKey="scope.row.editKey" :colData="scope.row.source" :platformList="platformList" />
                </template>
            </el-table-column>
            <el-table-column prop="target" label="修改结果" align="center" width="210">
                <template slot-scope="scope">
                   <auditComicRecordCol :publicClass="publicClass" :editKey="scope.row.editKey" :colData="scope.row.target" :platformList="platformList" />
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="提交审核时间" align="center" width="160">
                <template slot-scope="scope">
                    {{scope.row.createTime | timeFilter('yyyy年MM月dd hh:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column prop="auditStatus" label="发布时间" align="center" width="80">
                <template slot-scope="scope">
                    {{scope.row.createTime | timeFilter('yyyy年MM月dd hh:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column prop="auditStatus" label="状态" align="center" width="80">
                <template slot-scope="scope">
                    {{scope.row.auditStatus | auditStatusFilter}}
                </template>
            </el-table-column>
            <el-table-column v-if="[2].includes(level)" prop="userName" label="审核人" align="center" width="80"></el-table-column>
            <el-table-column prop="auditRemark" label="原因">
                <template slot-scope="scope">
                    <el-tooltip :disabled="!scope.row.auditRemark" class="item" effect="light" :content="scope.row.auditRemark" placement="top-start">
                        <div class="width100 single-ellipsis">{{scope.row.auditRemark  || '--'}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:13:35
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-01 13:50:35
 * @Description: 审核记录列表
 * @Company: 成都二次元动漫
 */
import { paginationMixin, comicMixin } from '@/mixins';
import { mapState } from 'vuex';
import auditComicRecordCol from './auditComic/auditComicRecordCol';

export default {
    mixins: [paginationMixin, comicMixin],
    data() {
        return {
            activeIndex: '1',
            searchData: {
                queryText: null
            },
            tableList: [],
            publicClass: []
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    mounted() {
    },
    created() {
        this.getPublicClass().then(res => (this.publicClass = res[0].child));
        this.getComicPlatformList();
        this.searchHandle();
    },
    methods: {
        // 搜索, 广播查询到子组件
        searchHandle() {
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.searchData = this.$options.data().searchData;
            this.searchHandle();
        },
        // 获取漫画类型列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => (this.platformList = data));
        },
        // 查询列表
        getTableList() {
            const { queryText } = this.searchData;
            return this.$api('getAuditLog_Admin', { queryText, ...this.pagination }).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows = [], pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
            });
        },
        // 重新组织列表数据
        formatTableList(list) {
            let result = {};
            list.forEach(item => {
                const hasTime = result.hasOwnProperty(item.createTime);
                if (!hasTime) result[item.createTime] = { ...item, list: [item] };
                else result[item.createTime].list.push(item);
            });
            return Object.values(result);
        },
        // 单行展开事件
        expandChangeHandle(currentExpandRow, allExpandRows) {
            if (this.batchExpandHandling) return false;
            const expandAllLen = allExpandRows.length;
            const tableListLen = allExpandRows.length;
            this.expandAll = expandAllLen === 0 ? false : (expandAllLen === tableListLen ? true : false);
        },
        // 批量展开操作
        batchExpandHandle() {
            this.batchExpandHandling = true;
            const tableComp = this.$refs.expandTable;
            const that = this;
            this.tableList.forEach(row => {
                tableComp.toggleRowExpansion(row, !that.expandAll);
            });
            this.expandAll = !this.expandAll;
            this.batchExpandHandling = false;
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    },
    components: {
        auditComicRecordCol
    }
};
</script>
<style lang="scss">
    .audit-recordlist{
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
        .searchForm{
            text-align: center;
            .input-with-select{
                width: 490px;
            }
        }
    }
</style>
